import React from "react";
import { stack as Menu } from "react-burger-menu";

let Sidebar = (props) => {

  return (
    <Menu {...props} right>
      <button className="menu-item" onClick={props.scrollServices}>
        SERVICES
      </button>

      <button className="menu-item" onClick={props.scrollWork}>
        WORK
      </button>

      {/* <button className="menu-item" onClick={props.scrollAbout}>
        ABOUT
      </button> */}

      <button className="menu-item" onClick={props.scrollContact}>
        CONTACT
      </button>
    </Menu>
  );
};
export default Sidebar
import React, {useRef, useEffect, useState} from 'react';
import './App.css';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import Navbar from './Components/Navbar'
import Pic_html from './images/Group 38HTML.png'
import Pic_react from './images/Group 36React.png'
import Pic_node from './images/Rectangle 76Node.png'
import Pic_js from './images/Group 37JS.png'
import Pic_billie from './images/billie.png'
import Pic_weather from './images/weather.png'
import emailjs, {init} from '@emailjs/browser';
import Aos from 'aos';
import "aos/dist/aos.css";
import Swal from 'sweetalert2';
import Waves from './images/waves.mp4'

function App() {

  useEffect(() => {
    Aos.init();
    Aos.refresh();
  }, []);

  const scroll = (ref) => ref.current.scrollIntoView({behavior: 'smooth'});
  const about = useRef();
  const services = useRef();
  const work = useRef();
  const contact = useRef();
  const home = useRef();
  const form = useRef();

  // Function for sending emqil on submit
  init('user_9zp2U7HridbErpWhyty4w'); 
  const sendEmail = (e) => {
    e.preventDefault();

    // Using Swal for confirmation alert
    emailjs.sendForm('service_3xyaghi', 'template_g8vp525', form.current, 'user_9zp2U7HridbErpWhyty4w')
      .then((result) => {
        const Toast = Swal.mixin({
          toast: true,
          position: 'bottom-end',
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
          }
        })
        
        Toast.fire({
          icon: 'success',
          title: 'Thank you for your message'
        })
          // alert("Message Sent Successfully");
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });
    e.target.reset()
  };

  // State for loading screen
  const [isLoading, setIsLoading] = useState(true);

    const handleLoading = () => {
      setTimeout(() => {
        setIsLoading(false);
      }, 2800)
    }

    useEffect(()=>{
      if (document.readyState == 'complete') {
        handleLoading()
      } else {
            window.addEventListener("load",handleLoading);
            return () => window.removeEventListener("load",handleLoading);
      }   
    },[])

  return (
    <HelmetProvider>
    <div>
    { !isLoading ?  
     (<div className="App" ref={home}>
      <video autoPlay muted loop playsInline id="myVideo"><source src={Waves} type='video/mp4'/></video>
      {/* Header section // First page */}
      <header>
      
        <Helmet>
            <title>kristiaan/gh | Full-Stack Developer</title>
                <meta charSet="utf-8" />
                <meta name="description" content="We build powerful and unique digital solutions, to help you launch, grow and drive results." />
                <meta name="keywords" content="Developer, javascript, js, web developer, web development, full-stack, front-end, back-end, front end, back end, html, css, express, git, node, mongo, react native, software, software engineer" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
        </Helmet>
        
        <Navbar className='navbar' scrollHome={()=>scroll(home)} scrollServices={()=>scroll(services)} scrollWork={()=>scroll(work)} scrollAbout={()=>scroll(about)} scrollContact={()=>scroll(contact)}/>
        
        <div className='header_intro'> 
          <h1 data-aos="fade-up" data-aos-duration="2000" data-aos-delay="1000">
            Launch fast,<br/>
            scale to millions.
          </h1>
          <p data-aos="fade-up" data-aos-duration="2000" data-aos-delay="1000">
            We build powerful and unique digital solutions,<br/>
            to help you launch, grow and drive results.
          </p>
        </div>
      </header>

{/* Main section */}
      <main>
        {/* Services provided // P.2 */}
        <section className='services' ref={services}>
          <h2 data-aos="fade-up" data-aos-duration="2000">What we do</h2>
          <p data-aos="fade-up" data-aos-duration="2000"> From crafting engaging and dynamic front-ends, to building robust and structured back-ends, we help you unlock your digital potential and bring your vision to life.</p>
          <div className='grid4' data-aos="fade-up" data-aos-duration="2000">
            <div>
              <img src={Pic_html} alt="Icon HTML & CSS" />
              <h3> HTML & CSS web design</h3>
              <p>Enterprise-class websites need consistent colors, fonts, and design to match the organization's branding guidelines.</p>
            </div>
            <div>
              <img src={Pic_react} alt="Icon react.js" />
              <h3>React JS development</h3>
              <p>Create complex software for your business, including web browsers and mobile application user interfaces.</p>
            </div>
            <div>
              <img src={Pic_node} alt="Icon Node.js" />
              <h3>Node.js development</h3>
              <p>Produce dynamic web page content, using event-driven programming to communicate with web servers.</p>
            </div>
            <div>
              <img src={Pic_js} alt="Icon javascript" />
              <h3>Javascript development</h3>
              <p>Build dynamic and responsive websites with the newest frameworks and libraries.</p>
            </div>
          </div>
        </section>

      {/* Featured projects // P.3 */}
        <section className='work' ref={work}>
          <h2 data-aos="fade-up" data-aos-duration="2000">Our work</h2>
          <div className='grid2' data-aos="fade-up" data-aos-duration="2000">
            <div>
              <h3>Billie’s Beer’s online shop</h3>
              <p>A comprehensive Full-Stack ecommerce solution showcasing our client's exceptional collection of beers. Streamlining the entire order process from initial placement to delivery, this solution provides a seamless user experience.</p>
              <p className='inactive'>Javascript / React / Axios / MongoDB / Express / Node / Stripe</p>
              <div className='projectButton'><a href="https://projects.barcelonacodeschool.com/billies-beer/">View website</a></div>
            </div>
            <div>
              <img src={Pic_billie} alt="Picture preview from webshop" />
            </div> 
          </div>
          <div className='grid2' data-aos="fade-up" data-aos-duration="2000">
            <div>
              <h3>Weather app</h3>
              <p>A weather app that offers real-time weather conditions based on the user's location. It features an autocomplete function and utilizes OpenWeatherMap API and Geolocation API to provide accurate, 10-day forecasts. Stay ahead of the weather with our innovative app.</p>
              <p className='inactive'>React Native</p>
              <div className='projectButton'><a href="" disabled  className='disabled'>Soon on AppStore</a></div>
            </div>
            <div>
              <img src={Pic_weather} alt="Picture preview web app" />
            </div>
          </div>

        </section>

{/* Contact  // P.4 */}
        <section className='contact' ref={contact}>
          <h2 data-aos="fade-up" data-aos-duration="2000">Let’s partner up!</h2>
          <p data-aos="fade-up" data-aos-duration="2000">Have an exciting project you need help with? I'm available for freelance opportunities. Contact us below!</p>
          <form className="form_container" ref={form} onSubmit={sendEmail} data-aos="fade-up" data-aos-duration="2000">
                <div className='gridForm'>
                  <div>
                    <label htmlFor="form_name"></label>
                    <input name="user_name" type="text" id='user_name' placeholder='Name'/>
                  </div>
                  <div>
                    <label htmlFor="user_email"></label>
                    <input name="user_email" type="email" id="user_email" placeholder='Email' />
                  </div>
                </div>
                  <label htmlFor="message"></label>
                  <input name="message" type="text" id="message" placeholder='Message'/>
                <div className='submit'><button type='submit'>Submit </button></div>
              </form>
              <footer className='grid_footer'>
              <div data-aos="fade-up" data-aos-duration="2000">
                  <ul>
                    <li className='footer_title'><a href='' disabled className='disabled'>Let's connect</a></li>
                    <li className='underline'><a href='' disabled className='disabled'>+34 655 42 52 92</a></li>
                    <li className='underline'><a href='mailto:kristiaangh@gmail.com'>kristiaangh@gmail.com</a></li>
                    <li className='underline'><a href='https://www.linkedin.com/in/kristiaan-goethaert/'>Connect on LinkedIn</a></li>
                  </ul>
                </div>
                <div data-aos="fade-up" data-aos-duration="2000">
                  <ul className='footer_links'>
                    <li><button onClick={()=>scroll(services)}>Services</button></li>
                    <li><button onClick={()=>scroll(work)}>Work</button></li>
                    {/* <li><button onClick={()=>scroll(about)}>About</button></li> */}
                    <li><button onClick={()=>scroll(contact)}>Contact</button></li>
                  </ul>
                </div>
              </footer>
        </section>
      </main>
    </div>) 
    : (
    // <ReactLoading type={'bars'} color={'grey'} height={667} width={375} />
    <div className='loader'><p className='load'>kristiaan/gh</p></div>
    )}
    </div>
    </HelmetProvider>
  );
}

export default App;

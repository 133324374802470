import React, {useEffect, useState} from "react";
import Sidebar from "./Sidebar.js"
import Aos from 'aos';
import "aos/dist/aos.css";

let Navbar = (props) => {

  useEffect(() => {
    Aos.init();
    Aos.refresh();
  }, []);

    const [show, setShow] = useState(false);
    const [lastScrollY, setLastScrollY] = useState(0);
  
    const controlNavbar = () => {
      if (typeof window !== 'undefined') { 
        if (window.scrollY < lastScrollY) { // if scroll down hide the navbar
          setShow(false); 
        } else { // if scroll up show the navbar
          setShow(true);  
        }
  
        // remember current page location to use in the next move
        setLastScrollY(window.scrollY); 
      }
    };
  
    useEffect(() => {
      if (typeof window !== 'undefined') {
        window.addEventListener('scroll', controlNavbar);
  
        // cleanup function
        return () => {
          window.removeEventListener('scroll', controlNavbar);
        };
      }
    }, [lastScrollY]);

    // const [active, setActive] = useState(document.activeElement);
        
    // useEffect(() => {
    //     setActive(document.activeElement);
    //     console.log(active)
    //     })

    const[headerActive, setHeaderActive] = useState(true)

    useEffect(() => {
        // if (typeof window !== 'undefined') { 
            if (window.scrollY > 650) { // if scroll down hide the navbar
              setHeaderActive(false); 
            } else { // if scroll up show the navbar
              setHeaderActive(true);  
            }
      
            // remember current page location to use in the next move
        //     setHeaderActive(headerActive); 
        //   }
        })


    return(
        <nav>
            <div className='hamburger'>
                <Sidebar pageWrapId={"page-wrap"} outerContainerId={"App"} scrollHome={props.scrollHome} scrollServices={props.scrollServices} scrollWork={props.scrollWork} scrollAbout={props.scrollAbout} scrollContact={props.scrollContact}/>
            </div>
            <div className={`navbar ${show && 'hidden'}`} style={ headerActive ? { mixBlendMode:'normal'} : {mixBlendMode:'difference'}}
>
                <div className="left_nav">
                    <ul>
                        <li data-aos="fade-down" data-aos-duration="200" data-aos-delay="100"><button onClick={props.scrollHome}>kristiaan/gh</button></li>
                    </ul>
                </div>
                <div className="right_nav">
                    <ul>
                        <li data-aos="fade-down" data-aos-duration="200" data-aos-delay="100"><button onClick={props.scrollServices}>Services</button></li>
                        <li data-aos="fade-down" data-aos-duration="200" data-aos-delay="200"><button onClick={props.scrollWork}>Work</button></li>
                        {/* <li data-aos="fade-down" data-aos-duration="200" data-aos-delay="300"><button onClick={props.scrollAbout}>About</button></li> */}
                        <li className="nav_contact" data-aos="fade-down" data-aos-duration="200" data-aos-delay="400"><button className="pulse" onClick={props.scrollContact}>Contact</button></li>
                    </ul>
                </div>
            </div>
        </nav>
    )
}
export default Navbar